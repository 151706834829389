import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'gatsby';
import * as nprogress from 'nprogress';
import useFlowProgressStore from '../FlowStore/FlowProgressStore';

import {
  pad,
  bigPad,
  centerText,
  linkColor,
} from './FlowChooseProvider.module.scss';
import {
  benefitsBar,
  benefitsBarText,
  benefitsBarTextMiddle,
  benefitsBarTextRight,
  benefitsBarDiv,
  benefitsBarCont,
  benefitsIconLeft,
  benefitsIconRight,
  benefitsIconMiddle,
} from '../ProgressBar/ProgressBar.module.scss';
import useLanguageStore from '../LanguageStore/LanguageStore';
import useFlowProviderStore from '../FlowStore/FlowProviderStore';
import useFlowCompanyDataStore from '../FlowStore/FlowCompanyDataStore';
import useFlowTerminalStore from '../FlowStore/FlowTerminalStore';
import Offer from '../Offer/Offer.tsx';
import ProgressBar from '../ProgressBar/ProgressBar';
import FlowTopText from './FlowTopText';
import FlowBottom from './FlowBottom';
import deliveryImg from '../../../static/images/png/delivery.jpg';
import refreshImg from '../../../static/images/png/refresh.jpg';
import smileyImg from '../../../static/images/png/smiley2.png';

type LanguageContent = {
  topText1: string;
  topText2: string;
  topText3: string;
  leftHeroHeader: string;
  leftHeroBody: string;
  errorMessageText: string;
  waitMessage: string;
  delivery: string;
  garantia: string;
  soporte: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  topText1: 'Congratulations! You have completed the test for ',
  topText2: 'payment solutions.',
  topText3:
    'Based on your answers, these payment solutions are the best fit for you.',

  leftHeroHeader: 'Almost done',
  leftHeroBody: 'We need a way to contact you.',
  errorMessageText:
    'An error has occured, contact us and we will help you with your request: info@puntoterminal.mx, 56 4238 4307',
  waitMessage: 'Please wait, your results are loading',

  delivery: 'Quick delivery',
  garantia: 'Warranty Included',
  soporte: 'Support Included',
};
content.Español = {
  topText1: '¡Felicidades! Has completado el test de ',
  topText2: 'terminales punto de venta.',
  topText3:
    'Basado en tus respuestas, estos son los soluciones de pago mejores para ti.',

  leftHeroHeader: 'Casi terminando',
  leftHeroBody: 'Necesitamos un modo para contactarle',
  errorMessageText:
    'Un error ha ocurido, contactanos y te ayudamos: info@puntoterminal.mx, 56 4238 4307',
  waitMessage: 'Por favor espera, tus resultados estan cargando',

  delivery: 'Entrega rápida',
  garantia: 'Garantía incluida',
  soporte: 'Soporte incluida',
};

const FlowChooseProvider = () => {
  const providerDataStore = useFlowProviderStore((state) => state.providerData);

  const mobileTerminals = useFlowTerminalStore(
    (state) => state.mobileTerminals
  );
  const staticTerminals = useFlowTerminalStore(
    (state) => state.staticTerminals
  );
  const inalambricoTerminals = useFlowTerminalStore(
    (state) => state.inalambricoTerminals
  );

  const [providerData, setProviderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const maxLoadTime = 60;
  const [counter, setCounter] = useState(maxLoadTime);

  useEffect(() => {
    nprogress.start();

    // Case 1: Max load time exceeded
    if (counter === 0) {
      setLoading(false);
      nprogress.done();
      return;
    }

    // Case 2: Data successfully loaded
    if (providerDataStore && providerDataStore.length > 0) {
      setProviderData(providerDataStore);
      setLoading(false);
      setCounter(0); // Reset counter for any future requests
      nprogress.done();
      return;
    }

    // Case 3: Countdown mechanism
    if (counter > 0 && loading) {
      const timeout = setTimeout(() => {
        setCounter(counter - 10 <= 0 ? 0 : counter - 10);
      }, 10000);

      return () => clearTimeout(timeout); // Clean up timeout
    }
  }, [providerDataStore, counter, loading]);

  const flowProgress: number = useFlowProgressStore(
    (state) => state.flowProgress
  );
  const sector: number = useFlowCompanyDataStore((state) => state.sector);
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  // if (flowProgress < 2) {
  //   return (
  //     <Container>
  //       <div className={bigPad} />
  //       <h2 className={centerText}>
  //         <Link to="/preferencias">Empieza con tus preferencias</Link>
  //       </h2>
  //       <div className={bigPad} />
  //     </Container>
  //   );
  // }

  if (loading) {
    return (
      <Container>
        <div className={bigPad} />
        <h2 className={centerText}>{choosenLanguage.waitMessage}</h2>
        <div className={bigPad} />
      </Container>
    );
  }

  if (providerData.length === 0 && loading === false) {
    return (
      <Container>
        <div className={bigPad} />
        <h2 className={centerText}>
          <a className={linkColor} href="mailto:info@puntoterminal.mx">
            {' '}
            {choosenLanguage.errorMessageText}{' '}
          </a>
        </h2>
        <div className={bigPad} />
      </Container>
    );
  }

  const offers = providerData?.map((provider, index) => (
    <Container>
      <Offer
        // Offerdata
        index={index}
        bank_id={provider.bank_id}
        tasaDescuentoCredit={provider.tasaDescuentoCredit}
        tasaDescuentoDebit={provider.tasaDescuentoDebit}
        total_year_cost={provider.total_year_cost}
        sector={sector}
        userPage={false}
        contractDate="Not-applicable"
        mobileTerminals={mobileTerminals}
        staticTerminals={staticTerminals}
        inalambricoTerminals={inalambricoTerminals}
      />
      <div className={pad} />
    </Container>
  ));

  return (
    <>
      <FlowTopText
        text1={choosenLanguage.topText1}
        text2={choosenLanguage.topText2}
        text3={choosenLanguage.topText3}
      />
      <ProgressBar />
      <div className={pad} />

      <div className={benefitsBar}>
        <div className={pad} />
        <div className={benefitsBarCont}>
          <Container>
            <div className="d-flex justify-content-between align-items-center position-relative ">
              <Image className={benefitsIconLeft} src={deliveryImg} alt="" />
              <div className={benefitsBar} />
              <Image className={benefitsIconMiddle} src={smileyImg} alt="" />
              <div className={benefitsBar} />

              <Image className={benefitsIconRight} src={refreshImg} alt="" />
            </div>
            <Row>
              <Col>
                <div className={benefitsBarText}>
                  {choosenLanguage.delivery}
                </div>
              </Col>
              <Col>
                <div className={benefitsBarTextMiddle}>
                  {choosenLanguage.soporte}
                </div>
              </Col>
              <Col>
                <div className={benefitsBarTextRight}>
                  {choosenLanguage.garantia}
                </div>
              </Col>
            </Row>
          </Container>
          <div className={pad} />
        </div>
      </div>
      <div className={pad} />
      <Container>{offers.length ? offers : <p>Default Markup</p>}</Container>
      <div className={pad} />
      <FlowBottom />
    </>
  );
};

export default FlowChooseProvider;
