 import * as nprogress from 'nprogress';
import axios from 'axios';
import useFlowUserStore from '../../FlowStore/FlowUserStore';
import useFlowCompanyDataStore from '../../FlowStore/FlowCompanyDataStore';
import useFlowProviderStore from '../../FlowStore/FlowProviderStore';

import 'nprogress/nprogress.css';
import useFlowTerminalStore from '../../FlowStore/FlowTerminalStore';

const apiURL = process.env.GATSBY_API_URL;

async function chooseProviderAPI(
  providerCreditFee: number,
  providerDebitFee: number,
  providerAnualCost: number
) {
  nprogress.start();

  // Import states
  const { sector } = useFlowCompanyDataStore.getState();
  const { email } = useFlowUserStore.getState();
  const { chosenProvider } = useFlowProviderStore.getState();
  const { mobileTerminals, staticTerminals, inalambricoTerminals } =
    useFlowTerminalStore.getState();

  const chosenProviderData = {
    sector_id: sector,
    tasa_credit: providerCreditFee,
    tasa_debit: providerDebitFee,
    costo_anual: providerAnualCost,
    email,
    bank_id: chosenProvider,
    contract_start: '00/00/0000', // Not set yet. Set in FlowContact.
    mobileTerminals,
    staticTerminals,
    wifiTerminals: inalambricoTerminals, // REMOVE FROM BACKEND
    gprsTerminals: 0, // REMOVE FROM BACKEND
  };

  const urlChooseProvider = `${apiURL}/save/selecction`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  // console.log(chosenProviderData);

  let success = false;
  try {
    const res = await axios.post(
      urlChooseProvider,
      JSON.stringify(chosenProviderData)
    );
    success = true;
    // console.log(res.data);
  } catch (error) {
    console.log(error);
  }

  nprogress.done();
  return success;
}

export default chooseProviderAPI;
