import React, { FunctionComponent } from 'react';
import { Container } from 'react-bootstrap';
import { miniPad, topText } from './FlowTopText.module.scss';

const FlowTopText: FunctionComponent<{
  text1: string;
  text2: string;
  text3: string;
}> = (props) => (
  <div className={topText} data-testid="flowTopText">
    <div className={miniPad} />
    <Container>
      {props.text1}
      <br />
      {props.text2}
      <br />
      {props.text3}
    </Container>
    <div className={miniPad} />
  </div>
);

export default FlowTopText;
