// extracted by mini-css-extract-plugin
export var addButton = "Offer-module--addButton--03ba9";
export var addButtonSimple = "Offer-module--addButtonSimple--42a10";
export var benefitsList = "Offer-module--benefitsList--cdd84";
export var bigPricingText = "Offer-module--bigPricingText--c6557";
export var buttonText = "Offer-module--buttonText--50557";
export var buttonTextLight = "Offer-module--buttonTextLight--74d8d";
export var buttonTextLightSameLine = "Offer-module--buttonTextLightSameLine--81360";
export var buttonTextSameLine = "Offer-module--buttonTextSameLine--16fee";
export var buyButtonCart = "Offer-module--buyButtonCart--34aee";
export var headerContainer = "Offer-module--headerContainer--0c834";
export var headerText = "Offer-module--headerText--9ad39";
export var heroButton = "Offer-module--heroButton--ec7c6";
export var hiddenTextFieldNoMargin = "Offer-module--hiddenTextFieldNoMargin--8d6d5";
export var leftColumn = "Offer-module--leftColumn--a3969";
export var middleColumn = "Offer-module--middleColumn--59532";
export var minipad = "Offer-module--minipad--7c862";
export var numberDiv = "Offer-module--numberDiv--051ad";
export var offerContainer = "Offer-module--offerContainer--a43bb";
export var offerDiv = "Offer-module--offerDiv--10ab0";
export var pad = "Offer-module--pad--9f354";
export var pricingElements = "Offer-module--pricingElements--b88f0";
export var pricingSummaryDiv = "Offer-module--pricingSummaryDiv--d51f9";
export var pricingTextElements = "Offer-module--pricingTextElements--55ebe";
export var providerImg = "Offer-module--providerImg--720b6";
export var readMoreText = "Offer-module--readMoreText--22bb5";
export var rightColumn = "Offer-module--rightColumn--8a9cc";
export var smallPad = "Offer-module--smallPad--221bf";
export var smallPricingText = "Offer-module--smallPricingText--aca00";
export var subtractButton = "Offer-module--subtractButton--e5116";
export var summary = "Offer-module--summary--547cf";
export var summaryPad = "Offer-module--summaryPad--45673";
export var terminalImg = "Offer-module--terminalImg--00f82";
export var textBox = "Offer-module--textBox--5fc1f";
export var totalCost = "Offer-module--totalCost--34eaf";
export var totalPrice = "Offer-module--totalPrice--cf334";