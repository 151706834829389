import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Button, Table, Col, Row, Image } from 'react-bootstrap';
import { findBankLogo } from './OfferHelper.tsx';
import {
  findTerminalText1,
  findTerminalText2,
  findTerminalText3,
  findTerminalTitle,
  findTerminalPicture,
  findTerminalBenefits,
} from './OfferHelperTerminal.tsx';
import {
  headerContainer,
  headerText,
  providerImg,
  terminalImg,
  paymentOptionsImg,
  numberDiv,
  offerDiv,
  offerContainer,
  textBox,
  leftColumn,
  benefitsList,
  middleColumn,
  rightColumn,
  buyButtonCart,
  addButtonSimple,
  readMoreText,
  bigPricingText,
  smallPricingText,
  minipad,
  totalPrice,
  totalCost,
  pricingElements,
  pricingTextElements,
  summary,
  pad,
  hiddenTextFieldNoMargin,
  pricingSummaryDiv,
  summaryPad,
} from './Offer.module.scss';
import useLanguageStore from '../LanguageStore/LanguageStore';
import useFlowProgressStore from '../FlowStore/FlowProgressStore';
import useFlowProviderStore from '../FlowStore/FlowProviderStore';
import chooseProviderAPI from '../Flow/apiFunctions/chooseProviderAPI';
import paymentOptions from '../../../static/images/png/payment_options.png';

type LanguageContent = {
  leftHeroHeader: string;
  leftHeroBody: string;
  // form
  personFirstname: string;
  personLastname: string;
  // Offer
  offerHeader: string;
  creditCards: string;
  debitCards: string;
  currencyPerMonth: string;
  totalCostText: string;
  buyButton: string;
  readMore: string;
  closeReadMore: string;
  // Cart
  cart: string;
  summary: string;
  numberOfTerminals: string;
  creditCardFee: string;
  debitCardFee: string;
  transactionFee: string;
  programmingFee: string;
  oneTimeFee: string;
  shipping: string;
  noTerminalChosen: string;
  totalCost: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  leftHeroHeader: 'Almost done',
  leftHeroBody: 'We need a way to contact you.',
  // Form
  personFirstname: 'First name',
  personLastname: 'Last name',
  // Offer
  offerHeader: 'Card Fees',
  creditCards: 'Credit Cards',
  debitCards: 'Debit Cards',
  currencyPerMonth: 'mxn',
  totalCostText: 'Total cost',
  buyButton: 'Add to Cart',
  readMore: 'Read More',
  closeReadMore: 'Close',
  // Cart
  cart: 'Your cart',
  summary: 'Summary',
  numberOfTerminals: 'Number of terminals ordered',
  creditCardFee: 'Credit card processing fee',
  debitCardFee: 'Debit card processing fee',
  transactionFee: 'Transaction fee',
  programmingFee: 'Programming fee',
  oneTimeFee: 'One time fee',
  shipping: 'Shipping',
  noTerminalChosen: 'No terminal choosen, go to preferences.',
  totalCost: 'Cost per terminal',
};
content.Español = {
  leftHeroHeader: 'Casi terminando',
  leftHeroBody: 'Necesitamos un modo para contactarle',
  // Form
  personFirstname: 'Nombre',
  personLastname: 'Apellido',
  // Offer
  offerHeader: 'Cobros de Tarjetas',
  creditCards: 'Tarjetas de Credito',
  debitCards: 'Tarjetas de Debito',
  currencyPerMonth: 'mxn',
  totalCostText: 'Costo Total',
  buyButton: 'Agregar al Carrito',
  readMore: 'Leer más',
  closeReadMore: 'Cerrar',
  // Cart
  cart: 'Tu carrito',
  summary: 'Resumen',
  numberOfTerminals: 'Cantidad de terminales',
  creditCardFee: 'Tarifa tarjetas credito',
  debitCardFee: 'Tarifa tarjetas debito',
  transactionFee: 'Tarifa por transaccion',
  programmingFee: 'Tarifa por programar terminal',
  oneTimeFee: 'Tarifa unico',
  shipping: 'Envio',
  noTerminalChosen: 'No terminal eligido, ve a preferencias.',
  totalCost: 'Costo unico por terminal',
};

interface ResultsProps {
  bank_id: any;
  terminalValueType: string;
}

const Results: React.FC<ResultsProps> = ({ bank_id, terminalValueType }) => {
  const characteristics = findTerminalText3(
    parseInt(bank_id),
    terminalValueType
  );

  return (
    <div>
      <div className={pad} />
      <div className={pad} />

      {characteristics.length > 0 && (
        <>
          <p>{characteristics[0]}</p>
          <ul>
            {characteristics.slice(1).map((characteristic, index) => (
              <li key={index}>{characteristic}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const Tasas = (props) => (
  <div className={pricingSummaryDiv}>
    <div className={summaryPad} />
    <Col xs={12} sm={12} md={12} lg={12}>
      <Row>
        <div className={pad} />
        <h5 className={summary}>{props.choosenLanguage.summary}</h5>
        <Col xs={6} sm={6} md={6} lg={8} className="col-auto">
          <div>
            <p className={pricingTextElements}>
              {props.choosenLanguage.numberOfTerminals}
            </p>
            <p className={pricingTextElements}>
              {props.choosenLanguage.creditCardFee}
            </p>
            <p className={pricingTextElements}>
              {props.choosenLanguage.debitCardFee}
            </p>
            <p className={pricingTextElements}>
              {props.choosenLanguage.transactionFee}
            </p>
            <p className={pricingTextElements}>
              {props.choosenLanguage.shipping}
            </p>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4} className="text-end">
          {/* numberOfTerminals */}
          <p className={pricingElements}>{props.numberOfTerminals}</p>
          {props.pricingFiller}

          {/* creditCardFee */}
          <p className={pricingElements}>{props.tasaDescuentoCredit} %</p>
          {props.pricingFiller}

          {/* debitCardFee */}
          <p className={pricingElements}>{props.tasaDescuentoDebit} %</p>
          {props.pricingFiller}

          {/* transactionFee */}
          <p className={pricingElements}>0 mxn</p>
          {props.pricingFiller}

          {/* shipping */}
          <p className={pricingElements}>
            <strong>GRATIS</strong>
          </p>
        </Col>
      </Row>
    </Col>
  </div>
);

const Offer: FunctionComponent<{
  // Offerdata
  bank_id: any;
  tasaDescuentoCredit: any;
  tasaDescuentoDebit: any;
  total_year_cost: any;
  sector: number;
  userPage: boolean;
  contractDate: string;
  mobileTerminals: number;
  staticTerminals: number;
  inalambricoTerminals: number;
}> = (props) => {
  const nextFlowPage = useFlowProgressStore((state) => state.nextFlowPage);
  const setChosenProvider = useFlowProviderStore(
    (state) => state.setChosenProvider
  );
  const setTerminalType = useFlowProviderStore(
    (state) => state.setTerminalType
  );
  const setTerminalName = useFlowProviderStore(
    (state) => state.setTerminalName
  );
  const setTasaDescuentoCredito = useFlowProviderStore(
    (state) => state.setTasaDescuentoCredito
  );
  const setTasaDescuentoDebito = useFlowProviderStore(
    (state) => state.setTasaDescuentoDebito
  );
  const setTotalYearCost = useFlowProviderStore(
    (state) => state.setTotalYearCost
  );

  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState<number>();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    if (!isBrowser) {
      return undefined;
    }

    setWidth(window.innerWidth);

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 426;
  const isTablet = width <= 768;
  const isDesktop = width >= 1208;

  let pricingFiller;
  // Shown on desktop and mobile, not tablet.
  if (isMobile === true || isDesktop === false) {
    pricingFiller = <p className={hiddenTextFieldNoMargin}>0</p>;
  }

  function getTerminalValue(
    mobileTerminals: number,
    staticTerminals: number,
    inalambricoTerminals: number
  ) {
    if (mobileTerminals > 0) {
      return { type: 'mobileTerminal', value: mobileTerminals };
    }
    if (staticTerminals > 0) {
      return { type: 'staticTerminal', value: staticTerminals };
    }
    if (inalambricoTerminals > 0) {
      return { type: 'inalambricoTerminal', value: inalambricoTerminals };
    }
    return { type: null, value: null };
  }

  const terminalValue = getTerminalValue(
    props.mobileTerminals,
    props.staticTerminals,
    props.inalambricoTerminals
  );

  const terminalName = findTerminalTitle(
    parseInt(props.bank_id),
    terminalValue.type
  );

  function chooseProviderFunc(
    bank_id: number,
    terminalType: string,
    tasaDescuentoCredit: number,
    tasaDescuentoDebit: number,
    total_year_cost: number
  ) {
    // console.log('Choosen provider is number');
    // console.log(bank_id);
    nextFlowPage();
    setChosenProvider(bank_id);
    setTerminalType(terminalType);
    setTerminalName(terminalName);
    setTasaDescuentoCredito(tasaDescuentoCredit);
    setTasaDescuentoDebito(tasaDescuentoDebit);
    setTotalYearCost(total_year_cost);
    chooseProviderAPI(tasaDescuentoCredit, tasaDescuentoDebit, total_year_cost);
  }

  const [showMoreText, setShowMoreTextText] = useState(false);
  const toggleShowMore = () => setShowMoreTextText(!showMoreText);

  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  const benefits: string[] = findTerminalText1(
    parseInt(props.bank_id),
    terminalValue.type
  );

  let chooseProviderButton: any;
  if (props.userPage) {
    chooseProviderButton = (
      <div className="d-grid gap-2">
        Tu contrato empezó el {props.contractDate}
      </div>
    );
  } else {
    chooseProviderButton = (
      <div className="d-grid gap-2">
        <Link to="/contratar">
          <Button
            variant="primary"
            className={buyButtonCart}
            size="lg"
            name="chosenProvider"
            value={parseInt(props.bank_id)}
            onClick={() =>
              chooseProviderFunc(
                props.bank_id,
                terminalValue.type,
                props.tasaDescuentoCredit,
                props.tasaDescuentoDebit,
                props.total_year_cost
              )
            }
          >
            {choosenLanguage.buyButton}
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <div className={offerDiv}>
      <div className={numberDiv}>{props.index + 1}</div>
      <div className={textBox}>
        {findTerminalBenefits(parseInt(props.bank_id), terminalValue.type)}
      </div>

      <div className={offerContainer}>
        <div className={leftColumn}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={headerContainer}>
              {/* <Image
                className={providerImg}
                variant="top"
                src={findBankLogo(parseInt(props.bank_id))}
                alt="Bank Logo"
              /> */}
              <h3 className={headerText}>
                {findTerminalTitle(parseInt(props.bank_id), terminalValue.type)}
              </h3>
            </div>

            <ul className={benefitsList}>
              {benefits.map((benefit) => (
                <li key={benefit}>{benefit}</li>
              ))}
            </ul>

            <p>
              {findTerminalText2(parseInt(props.bank_id), terminalValue.type)}
            </p>
          </Col>

          <Col xs={6} sm={6} md={6} lg={6}>
            <div className={minipad} />
            <Button className={addButtonSimple} onClick={toggleShowMore}>
              {showMoreText ? '-' : '+'}
            </Button>
            <h5 className={readMoreText} onClick={toggleShowMore}>
              {showMoreText
                ? choosenLanguage.closeReadMore
                : choosenLanguage.readMore}
            </h5>
          </Col>
          <div className={pad} />
          <Image
            className={paymentOptionsImg}
            src={paymentOptions}
            alt="Payment options contactless Google Pay, Apple Pay"
          />

          {showMoreText ? (
            <Results
              bank_id={props.bank_id}
              terminalValueType={terminalValue.type}
            />
          ) : null}
        </div>

        <div className={middleColumn}>
          <Image
            className={terminalImg}
            variant="top"
            src={findTerminalPicture(
              parseInt(props.bank_id),
              terminalValue.type
            )}
            alt="Terminal Punto de Venta"
          />
        </div>

        <div className={rightColumn}>
          <h5 className={summary}>{choosenLanguage.offerHeader}</h5>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row>
              <div className={pad} />
              <Col xs={6} sm={6} md={6} lg={8} className="col-auto">
                <div>
                  <p className={pricingTextElements}>
                    {choosenLanguage.creditCards}
                  </p>
                  <p className={pricingTextElements}>
                    {choosenLanguage.debitCards}
                  </p>
                </div>
              </Col>
              <Col xs={4} sm={4} md={6} lg={4} className="text-end">
                <p className={pricingElements}>{props.tasaDescuentoCredit} %</p>
                {pricingFiller}
                <p className={pricingElements}>{props.tasaDescuentoDebit} %</p>
              </Col>
            </Row>
          </Col>
          <h5 className={totalCost}>{choosenLanguage.totalCostText}</h5>
          <Row>
            <div className={totalPrice}>
              <div className={minipad} />
              <Row>
                <Col xs={12} sm={9} md={9} lg={9}>
                  <span className={bigPricingText}>
                    {props.total_year_cost
                      .toString()
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}{' '}
                  </span>
                </Col>
                <Col xs={6} sm={3} md={3} lg={3}>
                  <div className={minipad} />
                  <div className={minipad} />
                  <Row>
                    <span className={smallPricingText}>
                      {' '}
                      {choosenLanguage.currencyPerMonth}
                    </span>
                  </Row>
                  <Row>
                    {/* <span className={smallPricingText}>
                                    {' '}
                                    por 36 meses
                                  </span> */}
                  </Row>
                </Col>
                <div className={minipad} />
              </Row>
            </div>
          </Row>

          <div>{chooseProviderButton}</div>
          <div className={pad} />

          {showMoreText ? (
            <Tasas
              choosenLanguage={choosenLanguage}
              pricingFiller={pricingFiller}
              tasaDescuentoCredit={props.tasaDescuentoCredit}
              tasaDescuentoDebit={props.tasaDescuentoDebit}
              numberOfTerminals={terminalValue.value}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Offer;
